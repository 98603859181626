.card-box-shadow-rose{
    box-shadow: -10px 10px 5px 0px rgba(204,1,208,0.75);
    -webkit-box-shadow: -10px 10px 5px 0px rgba(204,1,208,0.75);
    -moz-box-shadow: -10px 10px 5px 0px rgba(204,1,208,0.75);
}

.card-box-shadow-green{
    box-shadow: -10px 10px 5px 0px rgba(177,209,30,0.75);
    -webkit-box-shadow: -10px 10px 5px 0px rgba(177,209,30,0.75);
    -moz-box-shadow: -10px 10px 5px 0px rgba(177,209,30,0.75);
}