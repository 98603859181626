.active-option{
    font-size: 1.5rem;
    font-weight: bolder;
}

.link {
    position: relative;
    text-decoration: none;
    color: white;
  }
  
  .link::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 5px;
    background-color: #CC01D0;
    transition: width 0.3s ease-in-out;
  }
  
  .link:hover::after {
    width: 100%;
  }