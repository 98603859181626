.glassContainer{
    background: rgba( 255, 255, 255, 0.60 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.60 );
    backdrop-filter: blur( 4px );
    -webkit-backdrop-filter: blur( 4px );
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
}

#bio{

    background:linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../assets/images/City.jpg');
    background-size: cover;
}

.zoom-blur-bg {
    position: relative;
    width: 100vw;
    overflow: hidden;
  }
  
  .zoom-blur-bg:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../assets/images/City.jpg'); /* Replace 'your-image-url.jpg' with your actual image URL */
    background-size: cover;
    filter: blur(10px) scale(1.1); /* Adjust the blur and scale values as needed */
    z-index: -1;
  }


.divider{
    height: 2px;
    width: 300px;
    content: '';
    background-color: black;
    margin: 1em 0;
}


.building-bg {
  position: absolute;
  display: block;
  content: '';
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  z-index: -10;
  background-image: url('../assets/images/City.jpg'); /* Replace 'your-image-url.jpg' with your actual image URL */
  background-size: cover;
  overflow: hidden;
}

.building-bg::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to top right, white 100%, rgb(255, 255, 255) 100%);
  z-index: -1;
  transform: skewY(300deg); /* Adjust the skew angle as needed */
  transform-origin: bottom right;
}

.building-bg-left {
  position: absolute;
  display: block;
  content: '';
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  z-index: -10;
  background-image: linear-gradient(to top right, white 100%, rgb(255, 255, 255) 100%);
  overflow: hidden;
}

.building-bg-left::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../assets/images/City.jpg'); /* Replace 'your-image-url.jpg' with your actual image URL */
  background-size: cover;
  z-index: -1;
  transform: skewY(300deg); /* Adjust the skew angle as needed */
  transform-origin: bottom right;
}

.triangle-container {
  position: absolute;
  width: 50%; /* Width of the triangle */
  height: 100%; /* Height of the triangle */
  overflow: hidden;
  transform: scaleX(-1);
}

.triangle-shape {
  width: 100%;
  height: 100%;
  clip-path: polygon(100% 0, 50% 100%, 100% 100%);
  filter: blur(2px);
  opacity: 0.6;
}

.triangle-shape-right {
  width: 100%;
  height: 100%;
  clip-path: polygon(0 0, 0 100%, 50% 0);
  opacity: 0.6;
  filter: blur(2px);
}

.swiper-wrapper{
  padding: 0 1.5rem;
}

@media screen and (max-width:600) {
  
.swiper-wrapper{
  padding: 0 ;
}
}