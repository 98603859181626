@import url(//fonts.googleapis.com/css?family=Lato:300:400);

body {
  margin:0;
}

h1 {
  font-family: 'Didot', sans-serif;
  font-weight:300;
  letter-spacing: 2px;
  font-size:48px;
  line-height: 48px;
}


h2 {
  font-family: 'Didot', sans-serif;
  font-weight:300;
  letter-spacing: 2px;
  font-size:40px;
  line-height: 40px;
}

h3 {
  font-family: 'Didot', sans-serif;
  font-weight:300;
  letter-spacing: 2px;
  font-size:32px;
  line-height: 32px;
}

h4 {
  font-family: 'Didot', sans-serif;
  font-weight:300;
  letter-spacing: 2px;
  font-size:36px;
  line-height: 36px;
}

h5 {
  font-family: 'Didot', sans-serif;
  font-weight:bold;
  letter-spacing: 2px;
  font-size:24px;
  line-height: 24px;
}
p {
  font-family: 'Helvetica Neue', sans-serif;                                         
  letter-spacing: 1px;
  font-weight:400;
  font-size:18px;
  color: #333333;
}

.header {
  position:relative;
  text-align:center;
  background: linear-gradient(60deg, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 100%);
  color:rgb(0, 0, 0);
}
.logo {
  width:50px;
  fill:white;
  padding-right:15px;
  display:inline-block;
  vertical-align: middle;
}

.inner-header {
  height:70vh;
  width:100%;
}


.waves {
  position:relative;
  width: 100%;
  height:300px;
  margin-bottom:-7px; /*Fix for safari gap*/
  min-height:100px;
  max-height:600px;
}

.content {
  position:relative;
  height:20vh;
  text-align:center;
  background-color: #CC01D0;
}

/* Animation */

.parallax > use {
  animation: move-forever 25s cubic-bezier(.55,.5,.45,.5)     infinite;
}
.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}
.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}
.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}
.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}
@keyframes move-forever {
  0% {
   transform: translate3d(-90px,0,0);
  }
  100% { 
    transform: translate3d(85px,0,0);
  }
}
/*Shrinking for mobile*/
@media (max-width: 768px) {
  .waves {
    height:120px;
    min-height:40px;
  }
  .content {
    height:30vh;
  }
  h1 {
    font-size:24px;
  }
}


/*Shrinking for mobile*/
@media (max-width: 480px) {
  .waves {
    height:60px;
    min-height:60px;
  }
  .content {
    height:30vh;
  }
  h1 {
    font-size:36px;
    line-height: 36px;
  }

  h2 {
    font-family: 'Didot', sans-serif;
    letter-spacing: 0;
    font-size:32px;
    line-height: 32px;
    font-weight: bolder;
  }
  
}

