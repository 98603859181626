.hexagon {
    cursor: default;
    position: relative;
    width: 170px; 
    height: 98.14954576223639px;
    background-color: #ffffff;
    margin: 49.07477288111819px auto 0 auto;
    box-shadow: 0 0 50px rgba(255,255,255,0);
    border-left: solid 5px #f47721;
    border-right: solid 5px #f47721;
    display: grid;
    transition: 0s;
}

.hexagon:before, .hexagon:after {
    content: "";
    position: absolute;
    z-index: 1;
    width: 120.20815280171307px;
    height: 120.20815280171307px;
    transform: scaleY(0.5774) rotate(-45deg);
    background-color: inherit;
    left: 19.895923599143465px;
    box-shadow: 0 0 50px rgba(255,255,255,0);
    transition: 0s;
}

.hexagon:before {
    top: -60.104076400856535px;
    border-top: solid 7.0710678118654755px #f47721;
    border-right: solid 7.0710678118654755px #f47721;
}

.hexagon:after {
    bottom: -60.104076400856535px;
    border-bottom: solid 7.0710678118654755px #f47721;
    border-left: solid 7.0710678118654755px #f47721;
}

.hexagon span {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    font-size: 25px;
    color: #f47721;
    top: 2.8867513459481287px;
    left: 0;
    width: 160px;
    height: 92.3760px;
    z-index: 2;
    background: inherit;
    transition: 0s;
}
    

